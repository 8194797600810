import React from 'react';
import './App.css';
import BodyWrapper from './pages/body-wrapper';

function App() {
  return (
    <BodyWrapper />
  );
}

export default App;
